import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';

const ProjectsPage = () => {
  return (
    <Container maxWidth="lg">
      <Typography variant="h3" gutterBottom>
        Projects
      </Typography>
      <Grid container spacing={2}>
        {projectsList.map((project) => {
          return (
            <Grid item xs={4} key={project.title}>
              <ProjectItem
                title={project.title}
                timeline={project.timeline}
                summary={project.summary}
                tech={project.tech}
              />
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
};

type ProjectItemProps = {
  title: string;
  timeline: string;
  summary: string;
  tech: string;
};

const ProjectItem = (props: ProjectItemProps) => {
  const { title, timeline, summary, tech } = props;
  return (
    <Card sx={{ maxWidth: 500 }} variant="outlined">
      <CardHeader title={title} subheader={timeline} />
      <CardContent>
        <Typography variant="body2">{summary}</Typography>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Tech Stack/Tools: {tech}
        </Typography>
      </CardContent>
    </Card>
  );
};

const projectsList = [
  {
    title: 'Government Department in NSW',
    timeline: 'June – Sept 2022 (Thoughtworks)',
    summary:
      'I was assigned as a senior developer working in the mobile team. The\
    team is developing a greenfield mobile app for both iOS and Android\
    using the latest technology which is planned to be launched for public\
    testing by the end of this year. The focus has been on delivering\
    excellent customer experience with good accessibility and building the\
    security in as part of the delivery.',
    tech: 'iOS (Swift, SwiftUI), Android (Kotlin, Jetpack Compose)'
  },
  {
    title: 'Major Airline in Australia',
    timeline: 'January 2022 – June 2022 (Thoughtworks)',
    summary:
      'I was assigned as a senior developer working in the enablement team. The team has worked closely with the various teams at client to understand the technical challenges and help addressing tech debts and work on improvements for the platform. The team was distributed across various cities in Australia, Thailand, and India, and collaborated with multiple teams. One of the focus areas was test strategy and implementing PACT tests to improve code quality.',
    tech: 'iOS (Swift), Android (Kotlin), Java spring boot, PACT'
  },
  {
    title: 'Multinational Pizza Restaurant Chain',
    timeline: 'January 2021 – January 2022 (Thoughtworks)',
    summary:
      'I was assigned as a senior developer working in the mobile app team for the first six months, then as a tech lead for the web team for the next six months. She has working closely with the client’s team to build and release the online ordering website and app. The team was distributed across Australia and was focused on frontend using React, Typescript and React Native. She led the team to plan the releases and the gradual rollout of the online ordering platform, working closely with the stakeholders in delivery and architecture. She also took the lead on the production support once the website was launched.',
    tech: 'React, Typescript, React Native'
  },
  {
    title: 'Leading Software Company',
    timeline: 'May 2020 – January 2021 (Thoughtworks)',
    summary:
      'I was assigned as a senior developer for the period of nine months working closely with the client’s platform team to build and release software which was consumed by their various product teams. The team was distributed across Australia and India and was focused on frontend using React, Typescript and ProseMirror framework. In the later part of the project I focused on performance data analysis and improvements using Apache Spark, Jupyter Notebook, and Databricks. I have also ran various lunch and learn sessions on good engineering practices and data analysis.',
    tech: 'React, Typescript, Javascript'
  },
  {
    title: 'Non Profit Organisation',
    timeline: 'April 2020 (Thoughtworks)',
    summary:
      'I was assigned as a senior developer for the period of one months working closely with the client to build a website to help communities organise volunteers during the pandemic. It was a React app with Node backend. Due to the small size of the team, I also took on some of the responsibilities of engagement lead and worked closely with the PO to align the scope of work and facilitate showcases.',
    tech: 'React, Node, AWS'
  },
  {
    title: 'Financial Technology Company',
    timeline: 'February 2020 – March 2020 (Thoughtworks)',
    summary:
      'I was assigned as a senior developer for the period of two months working closely with the client to build a personal finance management app in iOS and Android. The team was focused on the frontend features using ReactiveX. I was mainly working on iOS specialisation using RxSwift and RxCocoa. The backend was written in Ruby and pipeline was setup using CircleCI.',
    tech: 'Swift (iOS), Kotlin (Android), RxSwift'
  },
  {
    title: 'Leading Financial Institution',
    timeline: 'September 2019 – January 2020 (Thoughtworks)',
    summary:
      'I was assigned as a senior developer for the period of four months working closely with the client to build an authentication app for their internal staff as part of a large application. The application was a react app with GraphQL BFF and Node backend. Our cross-functional team was responsible for the fullstack development as well as deployments and maintains our devops pipeline using Jenkins, AWS, Batect and Terraform.',
    tech: 'React, GraphQL, Node, AWS'
  },
  {
    title: 'ASB Banking App',
    timeline: 'February 2017 – July 2019 (Software Specialist in ASB)',
    summary:
      'ASB Bank is one of the four major banks in New Zealand. I was in the Digital team focusing on the mobile development in both iOS and Android, and has stepped into the lead developer role. I have worked on a number of projects, including digital onboarding app which allows new customers to join the bank digitally without needing to go to the branch. She also did a scrum master role for a few months, before moving into a platform team to further build on the core capabilities for the mobile teams including improving the CI/CD pipeline, test automation and adding data layer for analytics.',
    tech: 'iOS (Swift, Objective-C), Android (Kotlin, Java), .NET, React, Typescript, Javascript'
  }
];

export default ProjectsPage;
