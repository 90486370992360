import { Box, Container, Grid } from '@mui/material';
import React from 'react';
import Typography from '@mui/material/Typography';
import profile from './profile_1.png';

const HomePage = () => {
  return (
    <Container maxWidth="md">
      <Grid container spacing={2} marginBottom={10}>
        <Grid item xs={8}>
          <Box padding={8}>
            <Typography variant="h3" gutterBottom>
              Mira Kim, Senior Engineer and Technologist
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <img src={profile} alt="profile" width="100%" />
        </Grid>
      </Grid>
      <Typography variant="body1" gutterBottom>
        I am a highly skilled and experienced software engineer specializing in
        mobile and web development. With a strong background in mobile
        technologies such as Swift, SwiftUI, Objective-C, RxSwift, Kotlin, and
        Android development, as well as web development using React, Typescript,
        Angular, and PHP, I bring a versatile skill set to every project.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Currently serving as a Senior Developer at Kasada, and previously as a
        senior consultant at Thoughtworks, I have had the privilege of working
        on a diverse range of projects with multiple clients. Throughout my
        tenure, I have taken on various roles, including senior developer, tech
        lead, and engagement lead, demonstrating my ability to lead teams and
        deliver high-quality solutions.
        <br />
        <br />
        My experience spans across different industries, having contributed to
        projects for government departments, major airlines, multinational
        restaurant chains, and leading software companies. For example, I have
        worked on developing a greenfield mobile app for a government department
        in New South Wales, focusing on delivering exceptional customer
        experiences with accessibility and security at the forefront.
        <br />
        <br />
        One of my key strengths lies in my commitment to good engineering
        practices and mentorship. I actively participate in the security
        community within Thoughtworks, organizing events, running infosec
        training, and facilitating community calls. Additionally, I have
        mentored junior developers both formally and informally, fostering a
        collaborative and knowledge-sharing environment.
        <br />
        <br />
        Furthermore, I have presented at industry conferences such as Xconf and
        DevWorld, where I shared my insights on topics like reusable UI
        components with SwiftUI. These experiences have allowed me to contribute
        to the broader software engineering community and stay up-to-date with
        the latest trends and technologies.
        <br />
        <br />
        In summary, I am a dedicated software engineer with a passion for
        delivering innovative solutions and driving technological advancements.
        With my extensive skills in mobile and web development, leadership
        abilities, and commitment to excellence, I am well-equipped to tackle
        any software engineering challenge.
      </Typography>
    </Container>
  );
};

export default HomePage;
