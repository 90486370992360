import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Box, Grid } from '@mui/material';
import { AccountBox, LinkedIn, Twitter } from '@mui/icons-material';

export default function Footer() {
  return (
    <Box
      sx={{
        p: 6
      }}
      component="footer"
    >
      <Container maxWidth="sm">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="body2" color="text.secondary" align="center">
            {'Copyright ©    Mira Kim   '}
            {new Date().getFullYear()}
            {'.'}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Link href="https://www.thoughtworks.com/profiles/m/mira-kim" color="inherit">
              <AccountBox />
            </Link>
            <Link href="https://www.linkedin.com/in/mirakim135/" color="inherit">
              <LinkedIn />
            </Link>
            <Link href="https://twitter.com/mrk144" color="inherit">
              <Twitter />
            </Link>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
