import React from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  NavLink
} from 'react-router-dom';
import HomePage from './home/HomePage';
import ProjectsPage from './projects/ProjectsPage';
import { SkillsPage } from './skills/SkillsPage';
import Typography from '@mui/material/Typography';
import { Container, Grid } from '@mui/material';
import Footer from './Footer';

function App() {
  return (
    <Container>
      <Router>
        <Grid container spacing={2} height={80} margin={5}>
          <Grid item xs={3}>
            <NavLink to="/">
              <Typography variant="h6">Home</Typography>
            </NavLink>
          </Grid>
          <Grid item xs={3}>
            <NavLink to="/projects">
              <Typography variant="h6">Projects</Typography>
            </NavLink>
          </Grid>
          <Grid item xs={3}>
            <NavLink to="/skills">
              <Typography variant="h6">Skills</Typography>
            </NavLink>
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
        <div>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/projects" element={<ProjectsPage />} />
            <Route path="/skills" element={<SkillsPage />} />
          </Routes>
        </div>
      </Router>
      <Footer />
    </Container>
  );
}

export default App;
