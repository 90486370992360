import { Container, Typography } from '@mui/material';
import React from 'react';

export const SkillsPage = () => {
  return (
    <Container maxWidth="lg">
      <Typography variant="h3" gutterBottom>
        Skills
      </Typography>
      <Typography variant="body1">
        Mobile development: Swift, SwiftUI, Objective-C, RxSwift, Kotlin, Jetpack Compose, Java, React Native
      </Typography>
      <Typography variant="body1">
        Web development (full stack): React, Typescript, Angular, PHP, NodeJS,
        GraphQL, .NET, Python
      </Typography>
      <Typography variant="body1">
        AWS: ECS, EC2, S3, DynamoDB, SNS, SQS, API Gateway
      </Typography>
      <Typography variant="body1">
        DevOps/Infra: Terraform, Batect, Docker, Jenkins, TFS Build, Azure Devops, Octopus
      </Typography>
      <Typography variant="body1">
        Databases: MS SQL Server, PostgreSQL
      </Typography>
      <Typography variant="body1">
        Data: Apache Spark, Jupyter Notebook, Databricks
      </Typography>
    </Container>
  );
};
